import { Pressable, SpaceContext } from "react-zoomable-ui";
import { ReactNode, useContext, useState } from "react";
import clamp from "lodash/clamp";

export type DraggableProps = {
  children: ReactNode;
  x: number;
  y: number;
  width: number;
  height: number;
  onDragPositionChange: (position: { x: number; y: number }) => void;
  onDragEnd: () => void;
};

export function Draggable({
  children,
  x,
  y,
  width,
  height,
  onDragPositionChange,
  onDragEnd,
}: DraggableProps) {
  const [{ panOffsetX, panOffsetY }, setPanOffset] = useState({
    panOffsetX: 0,
    panOffsetY: 0,
  });
  const context = useContext(SpaceContext);

  return (
    <Pressable
      style={{
        transform: `translate(${x}px, ${y}px)`,
        width: `${width}px`,
        height: `${height}px`,
        position: "absolute",
      }}
      capturePressThresholdMs={10}
      capturePressStyle={{ background: "yellow" }}
      onCapturePressStart={(coords, pressableUnderlyingElement) => {
        const vp = context.viewPort;
        const pressableVirtualSpaceRect = vp.translateClientRectToVirtualSpace(
          pressableUnderlyingElement,
        );
        // The offset is useful because it records which part of the Pressable
        // the pan started on. If we don't have this, when we calculate the new
        // x and y coordinates for the Pressable when the pan moves we would by
        // default treat it like the top left of the Pressable was always the
        // part being dragged.
        setPanOffset({
          panOffsetX: coords.x - pressableVirtualSpaceRect.left,
          panOffsetY: coords.y - pressableVirtualSpaceRect.top,
        });
      }}
      onCapturePressMove={(coords, pressableUnderlyingElement) => {
        // Note that almost all the complexity here is due to trying to bound
        // the pressable inside its parent. If we didn't care about that we
        // could just use the deltas in `coords` and add those to the current x
        // and y values.  We also wouldn't need `onCapturePanStart`.
        const vp = context.viewPort;
        const dragContainerBounds = vp.translateClientRectToVirtualSpace(
          pressableUnderlyingElement.parentElement!,
        );
        const { width: childWidth, height: childHeight } =
          vp.translateClientRectToVirtualSpace(pressableUnderlyingElement);

        const logicalX = clamp(
          coords.x - panOffsetX,
          dragContainerBounds.left,
          dragContainerBounds.right - childWidth,
        );
        const logicalY = clamp(
          coords.y - panOffsetY,
          dragContainerBounds.top,
          dragContainerBounds.bottom - childHeight,
        );

        onDragPositionChange({ x: logicalX, y: logicalY });
      }}
      onCapturePressEnd={() => {
        onDragEnd();
      }}
    >
      {children}
    </Pressable>
  );
}
