import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useRef } from "react";
import { Vector3 } from "three";
import { useArea } from "../../contexts/AreaContext";

// See https://codesandbox.io/p/sandbox/r3f-wasd-controls-wft0n?file=%2Fsrc%2FWasdControls.js%3A20%2C1-45%2C1
function useCodes() {
	const codes = useRef(new Set<string>());
	useEffect(() => {
		const onKeyDown = (e: KeyboardEvent) => codes.current.add(e.code);
		const onKeyUp = (e: KeyboardEvent) => codes.current.delete(e.code);
		window.addEventListener("keydown", onKeyDown);
		window.addEventListener("keyup", onKeyUp);
		return () => {
			window.removeEventListener("keydown", onKeyDown);
			window.removeEventListener("keyup", onKeyUp);
		};
	}, []);
	return codes;
}

const SPEED_WALK_FACTOR = 50 * 5 / 8; // Convert km\h to m\s
const SPEED_REAL_WALK = 4.5 * 5 / 8;
const SPEED_RUN_FACTOR = 200 * 5 / 8;

const moveSpeeds = [SPEED_REAL_WALK, SPEED_WALK_FACTOR, SPEED_RUN_FACTOR];
let speedIndex = 1;

const vec = new Vector3();

// Rotation logic from three/examples/jsm/controls/PointerLockControls.js
export default function WASDControls() {
	const { camera } = useThree();
	const { area } = useArea();
	const code = useCodes();

	const moveForward = (distance: number) => {
		vec.setFromMatrixColumn(camera.matrix, 0);
		vec.crossVectors(camera.up, vec);
		camera.position.addScaledVector(vec, distance);
	};

	const moveRight = (distance: number) => {
		vec.setFromMatrixColumn(camera.matrix, 0);
		camera.position.addScaledVector(vec, distance);
	};

	const moveUp = (distance: number) => {
		camera.position.addScaledVector(camera.up, distance);
	};

	// Update speed only when ShiftLeft is released
	useEffect(() => {
		const handleKeyUp = (event: KeyboardEvent) => {
			if (event.code === "ShiftLeft") {
				// Change the speed index on ShiftLeft release
				speedIndex = (speedIndex + 1) % moveSpeeds.length;
			}
		};

		window.addEventListener("keyup", handleKeyUp);
		return () => {
			window.removeEventListener("keyup", handleKeyUp);
		};
	}, []);

	useFrame((_, delta) => {
		const speed = moveSpeeds[speedIndex] / area.localToRealRatio;

		if (code.current.has("KeyW")) moveForward(delta * speed);
		if (code.current.has("KeyA")) moveRight(-delta * speed);
		if (code.current.has("KeyS")) moveForward(-delta * speed);
		if (code.current.has("KeyD")) moveRight(delta * speed);
		if (code.current.has("KeyQ")) moveUp(-delta * speed);
		if (code.current.has("KeyE")) moveUp(delta * speed);
		if (code.current.has("KeyR")) {
			const x = area.localWidth / 2.0;
			const y = area.localHeight / 2.0;
			camera.position.set(x, 1.8, y);
		}
	});

	return null;
}
