import { ChartSoundSource } from "../../../../__generated__/gql/graphql";
import { useArea } from "../../contexts/AreaContext";
import { useAreaLocalCoords } from "../../hooks/useAreaLocalCoords";

export type SoundSourceMeshProps = {
  source: ChartSoundSource;
};

export default function SoundSourceMesh({ source }: SoundSourceMeshProps) {
  const { area } = useArea();

  // Get the coordinates for positioning
  const { x, y } = useAreaLocalCoords(
    source.origin.globalLongitude,
    source.origin.globalLatitude,
  );
  const radiusOffset = source.shape.radius * area.localToRealRatio;

  // Find the color of the group associated with this sound source
  const groupColor = area.groups.find((group) => group.id === source.groupId)?.color || "red";

  return (
    <>
      {/* Primary Sphere with Group Color */}
      <mesh position={[x + radiusOffset, source.altitude * area.localToRealRatio, y + radiusOffset]}>
        <sphereGeometry args={[1]} />
        <meshBasicMaterial color={groupColor} />
      </mesh>
      
      {/* Wireframe Sphere with Group Color */}
      <mesh position={[x + radiusOffset, source.altitude, y + radiusOffset]}>
        <sphereGeometry args={[source.shape.radius * area.localToRealRatio]} />
        <meshBasicMaterial wireframe color={groupColor} />
      </mesh>
    </>
  );
}
