import { useQuery } from "@apollo/client";
import { ItemRenderer, Select } from "@blueprintjs/select";
import { Button, MenuItem } from "@blueprintjs/core";

import {
  AssetSoundFile,
  EditorReadFilesDocument,
} from "../../../__generated__/gql/graphql";

export type FileSelectOption = Pick<AssetSoundFile, "id" | "name" | "channels" | "status">;

export type FileSelectProps = {
  selected: FileSelectOption | null;
  onSelect?: (file: FileSelectOption) => void;
  filter?: (file: FileSelectOption) => boolean;
};

function optionToLabel(option: FileSelectOption) {
  return `${option.name} (${option.id})`;
}

const renderItem: ItemRenderer<FileSelectOption> = (
  option,
  { handleClick, handleFocus },
) => {
  return (
    <MenuItem
      text={option.name}
      label={option.id}
      key={option.id}
      onClick={handleClick}
      onFocus={handleFocus}
      roleStructure="listoption"
    />
  );
};

export default function FileSelect({ selected, onSelect, filter }: FileSelectProps) {
  const { data } = useQuery(EditorReadFilesDocument);

  if (!data) {
    return null; // FIXME read files once and use data from context
  }

  const items = data.assetSoundFiles!.filter(filter ? filter : () => true);

  return (
    <Select<FileSelectOption>
      filterable={false}
      items={items}
      itemRenderer={renderItem}
      onItemSelect={(file) => {
        onSelect && onSelect(file);
      }}
	  disabled={items.length === 0}
  >
      <Button
        text={selected ? optionToLabel(selected) : "No file"}
        rightIcon="double-caret-vertical"
		disabled={items.length === 0}
      />
    </Select>
  );
}
