import { HTMLSelect, HTMLSelectProps } from "@blueprintjs/core";

const OPTIONS = [
	"fixed",
	"passthrough",
	"ambisonic"
]

export type SourceKindSelectProps = Omit<HTMLSelectProps, 'options'>;

export default function SourceKindSelect(props: SourceKindSelectProps) {

  return (
    <HTMLSelect options={OPTIONS} {...props}/>
  );
}
